import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../../globalModels/Files";
// const _ = require('lodash');
import {User} from "../../../../globalModels/User";
import {Order} from "@/components/globalModels/Order";
import moment from 'moment';

export function ReturnGoods() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
    User: new User(),
    trackingNumberObject: null,
    changeTrackingNumberObject: false,
    trackingNumber: '',
    orderTrackingNumber: '',
    shippedDate: '',
    comment: '',
    Files: new Files({
      maxSizeFiles: 3,
      maxCountFiles: 5
    }),
    storage: null,
  }

  this.validation = {
    trackingNumberObject: false,
    trackingNumber: false,
    shippedDate: false,
    comment: false,
    storage: false,
  }

  this.validationTranslate = {
    trackingNumberObject: '',
    trackingNumber: '',
    shippedDate: '',
    comment: '',
    storage: '',
  }

  this.validationTxt = {
    trackingNumberObject: false,
    trackingNumber: false,
    shippedDate: false,
    comment: false,
    storage: false,
  }

  /**
   * Getters
   */
  this.getTrackingNumberObject = () => {
    return this.data.trackingNumberObject
  }
  this.getTrackingNumber = () => {
    return this.data.trackingNumber
  }
  this.getOrderTrackingNumber = () => {
    return this.data.orderTrackingNumber
  }
  this.getShippedDate = () => {
    return this.data.shippedDate
  }
  this.getComment = () => {
    return this.data.comment
  }
  this.getStorage = () => {
    return this.data.storage
  }

  /**
   * Setters
   */
  this.setTrackingNumberObject = (val) => {
    this.data.trackingNumberObject = val
  }
  this.setTrackingNumber = (val) => {
    this.data.trackingNumber = val
  }
  this.setOrderTrackingNumber = (val) => {
    this.data.orderTrackingNumber = val
  }
  this.setShippedDate = (val) => {
    this.data.shippedDate = val
  }
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setStorage = (val) => {
    this.data.storage = val
  }
}

/**
 * Global Setters
 */

ReturnGoods.prototype.setItem = function (item, that) {
  console.log(item, that);

  // this.setTrackingNumberObject(item.)
  this.data.Order.setCreatedAt(item.created_at)
  if (item.user) {
    this.data.User.setUser(item.user)
  }
  this.setTrackingNumber(item.tracking_number)
  if(item?.modelInstance) {
    this.setTrackingNumberObject(item.modelInstance)
    this.setOrderTrackingNumber(item.modelInstance.tracking_number)
  }

  if(!item?.modelInstance && item?.starter_track) {
    this.data.trackingNumberObject = {
      custom_tracking_number: item.starter_track
    }
  }

  this.setStorage(item?.warehouse_storage || null)
  this.setShippedDate(item.delivery_date)
  this.setComment(item.comment)

}

/**
 * Prepare Data
 */

ReturnGoods.prototype.prepareSave = function (edit = false) {
  let data = {
    'comment': this.getComment(),
    'tracking_number': this.getTrackingNumber(),
    'delivery_date': moment(this.getShippedDate()).format('YYYY-MM-DD'),
  }

  if (this.data.User.getUserId()) {
    data['user_id'] = this.data.User.getUserId()
  }

  if (this.data.storage) {
    data["warehouse_storage_id"] = this.data.storage?.id || null
  }

  if (this.data.changeTrackingNumberObject && typeof this.data.trackingNumberObject === 'object' && this.data.trackingNumberObject !== null && this.data.trackingNumberObject.tracking_number) {
    data['order_id'] = this.data.trackingNumberObject.id
    data['order_type'] = 'fbm'
  } else if(this.data.trackingNumberObject?.custom_tracking_number) {
    data['starter_track'] = this.data.trackingNumberObject?.custom_tracking_number
    data['order_type'] = 'fbm'
  }
  else {
    if(edit) {
      data['starter_track'] = ''
      data['order_type'] = ''
    }
  }

  return data
}


ReturnGoods.prototype.prepareFilesData = function (id) {
  let readerArray = []
  this.data.Files.getFiles().map(item => {

    let reader = new FormData();
    reader.append("file", item.file);
    reader.append("return_parcel_id", id)
    readerArray.push(reader)
  })
  return readerArray
}

/**
 * Validations
 */

ReturnGoods.prototype.firstValidation = function () {

  let validationItems = {
    comment: this.getComment(),
    trackingNumber: this.getTrackingNumber(),
    shippedDate: this.getShippedDate(),
  }

  let validationOptions = {
    comment: {type: ['empty']},
    trackingNumber: {type: ['empty']},
    shippedDate: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions) && this.data.Files.firstValidation())
}
